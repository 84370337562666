export const jobFamilies = [
    'Clinical Research',
    'Medical Administration',
    'Social and Community Services',
    'Health Education and Promotion',
    'Dentist',
    'Pharmaceutical Industry',
    'Digital Health',
    'Other Health Assisting Jobs',
    'Pharmacy',
    'Critical Care',
    'Data Analysis',
    'Non Clinical Research',
    'Laboratory and Pathology',
    'Entrepreneurship',
    'Medical Devices',
    'Project Management',
    'Non Traditional'
  ];

export const preferences = [
    'Drivers License Required',
    'Exclude Regulated Jobs',
  ];

export const stepper = [
    'Job Families',
    'Preferred Income',
    'Job Prospects',
    'Time to Market',
  ]

export const jobProspects = [
  'Very Good',
  'Good',
  'Fair',
  'Limited',
  'Work Placement',
  'Contract',
]

export const filterRegulated = [
  'Yes',
  'No',
  'Both'
]
export const filterCompetition = [
  'Good',
  'Fair',
  'Low'
]

export const filterJobGrowth = [
  'Steady',
  'Downward',
  'Growing'
]

export const filterCommitment = [
  'Full-time',
  'Contract',
  'Part-time'
]

export const filterVirtual = [
  'Remote',
  'Hybrid',
  'In-Person'
]

export const individualCoach = {
  1: { name: 'Individual coaching services' },
  2: { name: 'Personality specific job search' },
  3: { name: 'Burn out and resilience in job finding' },
  4: { name: 'Being overqualified in Canada' },
  5: { name: 'Healthcare Resume assistance' },
  6: { name: 'Interview preparation' },
  7: { name: 'Small group Q&A sessions' },
  8: { name: 'Job find guarantee program' },
  9: { name: 'Interview preparation' },
  10: { name: 'Improving job search skills' },
  11: { name: 'Networking techniques' },
  12: { name: 'LinkedIn profile good practices' },
  13: { name: 'Clinical research paths' },
  14: { name: 'Working in the private sector' },
  15: { name: 'Excellence in remote interactions' },
  16: { name: 'Taxes in Canada (personal and business)' },
  17: { name: 'Newcomer main objections' },
  18: { name: 'You are overqualified' },
  19: { name: 'Networking by personality' },
  20: { name: 'LinkedIn how to do it' },
  21: { name: 'I have a job- what next?' },
  22: { name: 'Short term vs long term strategies' },
}

export const groupCoach = {
  1: { name: 'Healthcare Landscape in Canada', duration: 120 },
  2: { name: 'Personal assessment for job finding', duration: 120 },
  3: { name: 'Alternative career choices for IEHPs', duration: 180 },
  4: { name: 'Non-licensed IEHP job families', duration: 120 },
  5: { name: 'Healthcare specific career paths', duration: 180 },
  6: { name: 'Intro to Canadian Digital Health sector', duration: 120 },
  7: { name: 'Canadian business etiquette', duration: 90 },
  8: { name: 'Creating a Canadian resume', duration: 90 },
  9: { name: 'Establishing a Canadian digital presence', duration: 90 },
  10: { name: 'Digital Healthcare landscape', duration: 120 },
  11: { name: 'Jobs in the pharmaceutical space', duration: 180 },
  12: { name: 'Improving job search skills', duration: 120 },
  13: { name: 'Networking techniques', duration: 90 },
  14: { name: 'LinkedIn - Why use it', duration: 120 },
  15: { name: 'Clinical research paths', duration: 120 },
  16: { name: 'Working in the private sector', duration: 90 },
  17: { name: 'Privacy in Canada', duration: 120 },
  18: { name: 'Confidentiality in Canadian Healthcare', duration: 120 },
  19: { name: 'Excellence in remote interactions', duration: 120 },
  20: { name: 'Taxes in Canada (personal and business)', duration: 120 },
  21: { name: 'Newcomer main objections', duration: 120 },
  22: { name: 'You are overqualified', duration: 120 },
  23: { name: 'Networking by personality', duration: 90 },
  24: { name: 'Healthcare space - where to start', duration: 120 },
  25: { name: 'Dealing with rejection', duration: 120 },
  26: { name: 'Entrepreneurship in Canada', duration: 30 },
  27: { name: 'Lack of Canadian experience', duration: 60 },
  28: { name: 'Building a brand in Canada', duration: 90 },
  29: { name: 'Job search - How to stand out', duration: 120 },
  30: { name: 'The ideal candidate', duration: 120 },
}

export const aboutUs = {
  1: { name: 'Mihai Csaki', title: 'Founder & Experienced Mentor and Guide', areas: 'Career Coach & Specialist, Alternative Career Paths, IMGs, Clinical Rsearch, Pharma Space, Digital health, Medical Affairs', text: 'International Medical Graduate ( EU license restricted), held executive positions in Marketing, Sales, Clinical research, Headhunting and Occupational Health services, Scientific Affairs. Experienced in Career coaching, Adult Learning, Pharmaceuticals, Medical Devices, Pharmacy, Entrepreneurship, with a deep interest in Digital Health.', picpath: 'Mihai', linkedurl: 'mihaicsaki' },
  2: { name: 'Abbas Zavar M.D.', title: 'Mentor and Digital Health Specialist', areas: 'Career Coach & Specialist, Health Informatics Coach, IMGs, Public Health, Digital Health', text: 'An international medical doctor who also held Master of Public Health and Master of Health Informatics, with solid expertise in adult education, coaching and mentoring. Outstanding knowledge and experience in the Canadian digital health domain: clinical decision support system, clinical business intelligence and clinical data sciences', picpath: 'Abbas', linkedurl: 'abbaszavar' },
}