<template>
  <!-- Loading spinner -->
  <v-container v-if="isLoading" class="d-flex flex-row align-center justify-center" style="height: 100vh;">
    <v-row justify="center" align="center">
      <v-col class="text-center">
        <v-progress-circular indeterminate size="70" width="7" color="primary"></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>

  <!-- Toolbar with Back button -->
  <v-toolbar color="white">
    <div class="d-flex align-center" style="padding-left: 25px; padding-top: 10px;">
      <v-btn icon color="primary" @click="clearAndGoBack">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </div>
  </v-toolbar>

  <!-- Main Layout -->
  <v-container fluid>
    <v-row >
      <!-- Filters Section (1/3 of the screen) -->
      <v-col
      cols="12"
      sm="3"
      md="3"
      lg="3"
      xl="3"
      class="d-flex flex-column"
    >
    <v-card
    class="pa-5 d-flex flex-column"
    flat
    style="position: sticky; top: 80px; width: 100%; height: calc(100vh - 80px);"
  >
    <!-- Scrollable Content Wrapper -->
    <div style="overflow-y: auto; flex: 1;">
      <!-- Found careers and Clear Filters -->
      <div class="mb-5 text-left d-flex align-center justify-between">
            <h3 class="text-subtitle-1" style="margin-right: 8px;">
              Found <span class="font-weight-bold">{{ filteredJobs.length }}</span> out of <span class="font-weight-bold">83</span> careers
            </h3>
            <v-btn
              color="primary"
              variant="outlined"
              small
              class="ml-auto"
              style="padding: 4px 8px; font-size: 12px;"
              @click="clearFilters"
            >
              Clear Filters
            </v-btn>
          </div>
          
          <!-- Job Families -->
          <div class="mb-2 text-left">
            <h3 class="text-subtitle-1 font-weight-medium">Job Families</h3>
            <div>
              <v-chip-group :key="chipGroupKey" column multiple>
              <v-chip
                v-for="family in jobFamilies"
                :key="family"
                @click="toggleFilter(selectedJobFamilies, family)"
                class="ma-1"
                filter
                color="primary"
                  >
                {{ formatFamilyName(family) }}
              </v-chip>
            </v-chip-group>
            </div>
          </div>
    
          <!-- Regulated -->
          <div class="mb-2 text-left">
            <h3 class="text-subtitle-1 font-weight-medium">Regulated</h3>
            <div>
              <v-chip-group :key="chipGroupKey" multiple>
              <v-chip
                v-for="option in filterRegulated"
                :key="option"
                @click="toggleFilter(selectedRegulated, option)"
                class="ma-1"
                filter
                color="primary">
                {{ option }}
              </v-chip>
            </v-chip-group>
            </div>
          </div>
    
          <!-- Prospects -->
          <div class="mb-2 text-left">
            <h3 class="text-subtitle-1 font-weight-medium">Prospects</h3>
            <div>
              <v-chip-group :key="chipGroupKey" multiple>
              <v-chip
                v-for="option in filterCompetition"
                :key="option"
                :color="selectedCompetition.includes(option) ? 'primary' : 'grey lighten-2'"
                @click="toggleFilter(selectedCompetition, option)"
                class="ma-1"
                filter
              >
                {{ option }}
              </v-chip>
            </v-chip-group>
            </div>
          </div>
    
          <!-- Growth -->
          <div class="mb-2 text-left">
            <h3 class="text-subtitle-1 font-weight-medium mb-3">Growth</h3>
            <div>
              <v-chip-group :key="chipGroupKey" multiple>
              <v-chip
                v-for="option in filterJobGrowth"
                :key="option"
                @click="toggleFilter(selectedJobGrowth, option)"
                class="ma-1"
                filter
                color="primary"
              >
                {{ option }}
              </v-chip>
            </v-chip-group>
            </div>
          </div>
    
          <!-- Virtual -->
          <div class="mb-2 text-left">
            <h3 class="text-subtitle-1 font-weight-medium">Virtual</h3>
            <div>
              <v-chip-group :key="chipGroupKey" multiple>
              <v-chip
                v-for="option in filterVirtual"
                :key="option"
                @click="toggleFilter(selectedVirtual, option)"
                class="ma-1"
                filter
                color="primary"
              >
                {{ option }}
              </v-chip>
            </v-chip-group>
            </div>
          </div>
    
          <!-- Commitment -->
          <div class="mb-2 text-left">
            <h3 class="text-subtitle-1 font-weight-medium">Commitment</h3>
            <div>
            <v-chip-group :key="chipGroupKey" multiple>
              <v-chip
                v-for="option in filterCommitment"
                :key="option"
                @click="toggleFilter(selectedCommitment, option)"
                class="ma-1"
                filter
                color="primary"
              >
                {{ option }}
              </v-chip>
            </v-chip-group>
            </div>
          </div>
          </div>
      </v-card>
      </v-col>

      <!-- Jobs Section (2/3 of the screen) -->
    <v-col
      cols="12"
      sm="9"
      md="9"
      lg="9"
      xl="9"
      class="d-flex flex-column"
      >
        <v-row class="pa-3" justify="center" style="row-gap: 24px; column-gap: 16px;">
        <!-- Dynamically Render Job Cards -->
        <v-col
          v-for="(job, index) in filteredJobs"
          :key="index"
          cols="12"
          sm="12"
          md="12"
          lg="5"
          xl="3"
        >
          <v-card class="pa-3" elevation="3">
              <v-card-item>
                <v-card-title class="d-flex flex-row align-center">
                  <div class="flex-grow-1 text-truncate" style="font-size: 1rem;">{{ job.Occupation }}</div>
                  <v-spacer></v-spacer>
                  <v-btn x-small flat icon @click="toggleHeart(job.ID)" style="padding: 2px;">
                    <v-icon :color="isJobSaved(job.ID) ? 'red' : 'grey'" size="20">
                      {{ heartIcon(job.ID) }}
                    </v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-subtitle class="d-flex align-center">
                  <v-icon color="green" icon="mdi-cash" size="small" style="margin-right: 5px;"></v-icon>
                  <span>${{ (job.Income_Min / 1000).toFixed(0) }}k - ${{ (job.Income_Max / 1000).toFixed(0) }}k</span>
                </v-card-subtitle>
              </v-card-item>
      
              <v-card-text>
                <v-row align="center" class="mx-0 pt-1">
                  <span>Regulated: {{ job.Regulated }}</span>
                  <v-icon :color="getColor(job.Regulated)" :icon="getIcon(job.Regulated)" size="small" style="margin-left: 5px;"></v-icon>
                </v-row>
                <v-row align="center" class="mx-0 pt-1">
                  <span>Type: <strong>{{ job.Commitment }}</strong></span>
                </v-row>
                <v-row justify="space-around" style="padding: 15px 0;">
                  <v-chip :color="getColor(job.Prospects.replace(' ', '_'))" style="height: auto; margin-right: 10px;">
                    <v-icon :color="getColor(job.Prospects.replace(' ', '_'))" icon="mdi-finance" size="small" style="margin-right: 5px;"></v-icon>
                    {{ job.Prospects }}
                  </v-chip>
                  <v-chip style="height: auto; margin-right: 10px;">
                    <v-icon color="green" icon="mdi-map-marker" size="x-small" style="margin-right: 5px;"></v-icon>
                    {{ job.Virtual ? job.Virtual.split(',')[0] : 'N/A' }}
                  </v-chip>
                  <v-chip style="height: auto; margin-right: 10px;">
                    <v-icon color="green" icon="mdi-tag" size="x-small" style="margin-right: 5px;"></v-icon>
                    {{ job.Count.length }} {{ job.Count.length > 1 ? '+' : '' }}
                  </v-chip>
                </v-row>
              </v-card-text>
              <v-divider class="mx-4 mb-1"></v-divider>
              <v-card-actions>
                <v-btn
                  color="primary"
                  text="Details"
                  block
                  border
                  :to="{ name: 'JobDetails', params: { jobId: job.ID } }"
                ></v-btn>
              </v-card-actions>
          </v-card>
        </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, onMounted, nextTick, computed } from 'vue';
import { useUserStore } from '../store/store';
import router from '../router/router';
import instance from '../store/axios';
import {
  filterRegulated,
  filterCompetition,
  filterJobGrowth,
  filterCommitment,
  filterVirtual,
} from '../config/forms';

export const iconMap = {
  Yes: { color: 'orange', icon: 'mdi-alert-circle' },
  Both: { color: 'blue', icon: 'mdi-alert-box' },
  No: { color: 'green', icon: 'mdi-check-circle' },
  Very_Good: { color: 'green', icon: 'mdi-finance' },
  Good: { color: 'green', icon: 'mdi-finance' },
  Fair: { color: 'orange', icon: 'mdi-finance' },
  Stable: { color: 'blue', icon: 'mdi-finance' },
  Low: { color: 'green', icon: 'mdi-finance' },
  High: { color: 'blue', icon: 'mdi-finance' },
  Steady: { color: 'blue', icon: 'mdi-finance' },
  Growing: { color: 'green', icon: 'mdi-finance' },
};

export default {
  setup() {
    const jobs = ref([]);
    const jobFamilies = ref([]);
    const selectedJobFamilies = ref([]);
    const selectedRegulated = ref([]);
    const selectedCompetition = ref([]);
    const selectedJobGrowth = ref([]);
    const selectedVirtual = ref([]);
    const selectedCommitment = ref([]);
    const isLoading = ref(false);
    const drawer = ref(false);
    const userStore = useUserStore();
    const chipGroupKey = ref(0);
    const cf_saved = ref(
      sessionStorage.getItem('cf_saved') ? JSON.parse(sessionStorage.getItem('cf_saved')) : []
    );

    // Toggle chip selection for filters
    const toggleFilter = (array, value) => {
      const index = array.indexOf(value);
      if (index === -1) {
        array.push(value);
      } else {
        array.splice(index, 1);
      }
    };

    const toggleDrawer = () => {
      drawer.value = !drawer.value;
    };

    const isJobSaved = (jobId) => cf_saved.value.includes(jobId);

    const heartIcon = (jobId) => (isJobSaved(jobId) ? 'mdi-heart' : 'mdi-heart-outline');

    const getColor = (category) => iconMap[category]?.color || 'grey';
    const getIcon = (category) => iconMap[category]?.icon || 'mdi-information';

    const toggleHeart = async (jobId) => {
      if (isJobSaved(jobId)) {
        const index = cf_saved.value.indexOf(jobId);
        cf_saved.value.splice(index, 1);
        await nextTick();
        try {
          await instance.delete('profile/saved', {
            data: { jobId },
            headers: { 'Content-Type': 'application/json' },
          });
        } catch (error) {
          console.error('Failed to delete job:', error);
          cf_saved.value.push(jobId);
        }
      } else {
        cf_saved.value.push(jobId);
        await nextTick();
        try {
          await instance.put('profile/saved', {
            data: { jobId },
            headers: { 'Content-Type': 'application/json' },
          });
        } catch (error) {
          console.error('Failed to save job:', error);
          const index = cf_saved.value.indexOf(jobId);
          cf_saved.value.splice(index, 1);
        }
      }
      sessionStorage.setItem('cf_saved', JSON.stringify(cf_saved.value));
    };

    const clearAndGoBack = () => {
      router.go(-1);
    };

    // Apply all filters to the jobs
    const filteredJobs = computed(() => {
      return jobs.value.filter((job) => {
        const matchesFamilies =
          selectedJobFamilies.value.length === 0 ||
          job.Count.some((family) => selectedJobFamilies.value.includes(family));

        const matchesRegulated =
          selectedRegulated.value.length === 0 || selectedRegulated.value.includes(job.Regulated);

        const matchesCompetition =
          selectedCompetition.value.length === 0 || selectedCompetition.value.includes(job.Prospects);

        const matchesJobGrowth =
          selectedJobGrowth.value.length === 0 || selectedJobGrowth.value.includes(job.Trend);

        const matchesVirtual =
          selectedVirtual.value.length === 0 ||
          selectedVirtual.value.some((selected) =>
            job.Virtual.split(',').map((v) => v.trim()).includes(selected)
          );

        const matchesCommitment =
          selectedCommitment.value.length === 0 ||
          selectedCommitment.value.some((selected) =>
            job.Commitment.split(',').map((c) => c.trim()).includes(selected)
          );

        return (
          matchesFamilies &&
          matchesRegulated &&
          matchesCompetition &&
          matchesJobGrowth &&
          matchesVirtual &&
          matchesCommitment
        );
      });
    });

    const clearFilters = () => {
      selectedJobFamilies.value = [];
      selectedRegulated.value = [];
      selectedCompetition.value = [];
      selectedJobGrowth.value = [];
      selectedVirtual.value = [];
      selectedCommitment.value = [];

      // Increment the key to force re-render of chip groups
      chipGroupKey.value += 1;
    };

    // Load jobs and job families on mount
    onMounted(async () => {
      try {
        isLoading.value = true;

        const storedResults = sessionStorage.getItem('cf_results');
        if (storedResults) {
          jobs.value = JSON.parse(storedResults);
        } else if (userStore.searchResults.length > 0) {
          jobs.value = userStore.searchResults;
        } else {
          const cf_search = sessionStorage.getItem('cf_search')
            ? JSON.parse(sessionStorage.getItem('cf_search'))
            : null;
          const response = await instance.get('search/results', {
            params: cf_search,
            headers: { 'Content-Type': 'application/json' },
          });

          jobs.value = response.data.map((job) => ({
            Occupation: job.occupation,
            Description: job.description,
            Prospects: job.job_prospects,
            Regulated: job.regulated,
            Income_Min: job.income_min,
            Income_Max: job.income_max,
            ID: job.id,
            Trend: job.job_growth,
            Virtual: job.virtual,
            Count: job.Families,
            Commitment: job.commitment,
          }));

          userStore.setCfResults(jobs.value);
        }

        // Populate job families
        if (Array.isArray(userStore.jobFamilies) && userStore.jobFamilies.length > 0) {
          jobFamilies.value = userStore.jobFamilies;
        } else {
          const familiesSet = new Set();
          jobs.value.forEach((job) => {
            job.Count.forEach((family) => {
              familiesSet.add(family);
            });
          });
          jobFamilies.value = Array.from(familiesSet);
          userStore.setJobFamilies(jobFamilies.value);
        }

        isLoading.value = false;
      } catch (error) {
        console.error('Failed to fetch jobs:', error);
        isLoading.value = false;
      }
    });

    const formatFamilyName = (family) => {
      return family.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
    };

    return {
      jobs,
      filteredJobs,
      jobFamilies,
      selectedJobFamilies,
      selectedRegulated,
      selectedCompetition,
      selectedJobGrowth,
      selectedVirtual,
      selectedCommitment,
      heartIcon,
      isLoading,
      isJobSaved,
      toggleHeart,
      clearAndGoBack,
      toggleDrawer,
      drawer,
      getColor,
      getIcon,
      formatFamilyName,
      toggleFilter,
      filterRegulated, // Added here
      filterCompetition, // Added here
      filterJobGrowth, // Added here
      filterVirtual, // Added here
      filterCommitment, // Added here
      clearFilters,
      chipGroupKey
    };
  },
};
</script>
<style>
.small-checkbox .v-label {
  font-size: 10px; /* Smaller font size */
}
.filter-title {
  font-weight: bold;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.v-toolbar {
  display: flex;
  justify-content: flex-start;
}

.v-toolbar-title {
  margin-right: 10px;
}

.ml-2 {
  margin-left: 8px; /* Adjust spacing as needed */
}

.custom-hover-shadow {
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2); /* Shadow to bottom-right */
  transition: box-shadow 0.3s ease; /* Smooth hover transition */
}

.custom-hover-shadow:hover {
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.3); /* Increase shadow on hover */
}
</style>